<template>
    <!-- <BT-Blade hideBladeHeader light> -->
    <v-card>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <div class="text-h4">Terms and Conditions</div>
                    <div class="text-subtitle-2">Last Updated: 13th June, 2023</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-none d-md-flex" md="4">
                    <v-list class="overflow-y-auto" :style="`height: ${height}`">
                        <v-list-item v-for="(section, sInd) in termsData.sections" :key="sInd" @click="scrollIntoView(sInd)">
                            <v-list-item-content>
                                <v-list-item-title class="primary--text text-wrap font-weight-bold">{{ section.header }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="8">
                    <v-sheet :style="`height: ${height}`" tile class="overflow-y-auto" id="termsE">
                        <v-container v-for="(section, sInd) in termsData.sections" :key="sInd + 'asdf'" :id="sInd + 'asdf'">
                            <div class="text-h5 primary--text font-weight-bold my-4">{{ section.header }}</div>
                            <div v-for="(line, lInd) in section.lines" :key="lInd" :style="'margin-left: ' + getIndentation(line)">
                                <p v-if="typeof line == 'string'">{{ line }}</p>
                                <p v-else-if="line.text != null">{{ line.text }}</p>
                                <p v-else-if="isLengthyArray(line.words)">
                                    <span v-for="(word, wInd) in line.words" :key="wInd">
                                        <span v-if="typeof word == 'string'">{{ word }}</span>
                                        <span v-else-if="word.link != null">
                                            <a :href="word.link" target="_blank">{{ word.text }}</a>
                                        </span>
                                        <span v-else-if="word.text != null">{{ word.text }}</span>
                                    </span>
                                </p>
                            </div>
                        </v-container>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
        
    <!-- </BT-Blade> -->
</template>

<script>
import termsData from '~visit/Terms-And-Conditions.js';

export default {
    name: 'TermsAndConditions',
    data() {
        return {
            termsData: termsData
        }
    },
    props: {
        height: {
            type: String,
            default: 'calc(100vh - 158px)'
        }
    },
    methods: {
        scrollIntoView(ind) {
            this.$vuetify.goTo(document.getElementById(ind.toString() + 'asdf'), { container: document.getElementById('termsE')});
        },
        getText(line) {
            if (typeof line == 'string') {
                return line;
            }
            else if (line.text != null) {
                return line.text;
            }
            else if (this.isLengthyArray(line.words)) {
                return line.words[0];
            }
        },
        getIndentation(line) {
            var txt = this.getText(line);

            if (txt != null && txt.startsWith('(')) {
                return '15px';
            }

            return '0px';
        }
    }
}
</script>

<!-- <style scoped>
    p, li {
        font-size: 18px;
        margin-top: 5px;
    }

    li {
        margin-top: 10px;
    }
</style> -->